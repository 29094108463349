/* App global styles */
.App {
  text-align: center;
  background-image: url("../public/images/background.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

/* Header and Navbar */
.App-header {
  padding-top: 6vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.navbar {
  box-shadow: 4px 10px 20px 0 #000;
  border-radius: 10px;
}

#basic-navbar-nav {
  font-weight: 800;
  text-align: left;
}

#basic-navbar-nav .nav-link {
  color: black !important;
}

.navbar {
  width: 80vw;
}

.navbar-brand img {
  width: 80px;
}

/* Main page containers*/
.privacy-policy-page-container.container, .contact-page-container.container, .games-studio-page-container.container, .services-page-container.container, .about-page-container.container, .page-container.container {
  max-width: 80vw;
  padding: 0;
}

/* Index Page */
.intro-slide-1, .intro-slide-2 {
  display: flex;
  margin-top: 2%;
  background: #FFFFFF;
  padding: 3rem;
  border-radius: 10px;
  color: #000000;
}

.splide__track.splide__track--loop.splide__track--ltr.splide__track--draggable {
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-top: 2%;
  box-shadow: 4px 10px 20px 0 #000;
}

.intro, .intro-2 {
  width: 60%;
  margin: auto;
  padding: 2%;
}

.intro-header h1, .intro-header-2 h1 {
  text-transform: uppercase;
  font-weight:  bolder;
  text-align: left;
  font-size: xxx-large;
  color: #1f0f5a;
}

.intro-subheader-2 {
  text-align: left;
  font-weight: bolder;
}

.intro-text, .intro-text-2 {
  text-align: left;
  padding: 2% 0;
}

.intro-image img {
  width: 75%;
  border-radius: 10px;
}

.intro-image, .intro-image-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.intro-image-2 img {
  width: 100%;
  border-radius: 10px;
}

.read-more {
  margin-top: 2%;
}

.read-more-button.btn.btn-lg {
  background: #B60C18;
  box-shadow: 4px 10px 20px 0 #000;
  border: .5px solid #FFF;
  padding: 15px 45px;
  text-transform: uppercase;
}

.slider {
  margin-top: 2%;
}

.slider svg {
  cursor: pointer;;
}

/* Footer */
.footer-container.container {
  min-width: 100vw;
  background: #1f0f5a;
  color: #FFFFFF;
  text-transform: uppercase;
  margin-top: 1%;
  padding: 2.01%;
  position: static;
  bottom: 0;
}

.footer-sections-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  width: 80vw;
  margin: auto;
}

.logo-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.logo-container a {
  align-self: baseline;
}

.logo-container, .company-container, .information-container, .socials-container, .socials-container {
  width: 20%;
}

.company-container-header, .information-container-header, .socials-container-header, .socials-container-header {
  text-decoration: underline;
}

.company-container-list, .information-container-list, .socials-container-list, .socials-container-sub-list {
  list-style: none;
  text-align: center;
  width: 100%;
  padding: 0;
  text-align: center
}

.company-container-list a, .information-container-list a, .socials-container-list a, .socials-container-sub-list a {
  color: #FFFFFF;
  text-decoration: none;
}

.company-container-list a:hover, .information-container-list a:hover, .socials-container-list a:hover, .socials-container-sub-list a:hover {
  color: #FFFFFF;
}

.socials-container-list {
  text-transform: lowercase;
}

.socials-container-subheader {
  text-transform: capitalize;
}

.socials-container-sub-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.rights {
  text-transform: capitalize;
}

.horizontal-rule {
  max-width: 80vw;
  margin: 1% auto;
}

.ewf-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  max-width: 80vw;
  margin: auto;
}

.textwidget, .textwidgetspan p  {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  font-size: .8rem;
  text-transform: capitalize;
}

#mgaLogo {
  width: 15vw;
}

#footer-middle-widget-area-2, #footer-middle-widget-area-3 {
  width: 30vw;
  padding: 1rem;
  text-align: left;
  margin-left: 1rem;
}

/* About page */
.about-page-container.container {
  margin-top: 2%;
  margin-bottom: 4%;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 4px 10px 20px 0 #000;
}

.about-page-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: left;
}

.about-page-slide {
  width: 75%;
}

.about-page-header {
  color: #1f0f5a;
}

.about-page-image {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* Services page */
.services-page-container.container {
  margin-top: 2%;
  margin-bottom: 3.25%;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 4px 10px 20px 0 #000;
  text-align: left;
  padding: 40px;
}

.services-page-header {
  font-size: xxx-large;
  color: #1f0f5a;
  font-weight: 900;
  text-transform: uppercase;
}

.services-page-subheader {
  font-weight: 900;
  margin-bottom: 2rem;
  font-size: xx-large;
  text-transform: uppercase;
}

.services-page-service-subheader {
  font-weight: 900;
  text-decoration: underline;
  font-size: x-large;
  text-transform: uppercase;
}

.services-page-service {
  margin-bottom: 2rem;
}

.services-page-service p {
  font-size: large;
}

/* Game Studio */
.games-studio-page-container.container {
  margin-top: 2%;
  margin-bottom: 3.25%;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 4px 10px 20px 0 #000;
}

.slide-container {
  width: 80vw;
}

.slide-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  padding: 2rem;
}

.slide-header {
  text-transform: uppercase;
  color: #1f0f5a;
}

.slide-description, .slide-details {
  text-align: left;
  padding: 0 30px;
}

.slide-image {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}

/* Contact Page */
.contact-page-container.container {
  margin-top: 2%;
  margin-bottom: 3.25%;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 4px 10px 20px 0 #000;
  padding: 40px;
}

.contact-page-form-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.contact-page-form-container form {
  width: 70%;
}

.contact-page-header {
  text-transform: uppercase;
  text-align: left;
  font-size: xxx-large;
  color: #1f0f5a;
}

a .form-button.btn-lg:hover, .form-button.btn-lg, .contact-form-button.btn-lg {
  background: #B60C18;
  box-shadow: 4px 10px 20px 0 #000;
  border: .5px solid #FFF;
  text-transform: uppercase;
  float: left;
}

/* Privacy Policy  page */
.privacy-policy-page-container.container {
  margin-top: 2%;
  margin-bottom: 3.25%;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 4px 10px 20px 0 #000;
}

.privacy-policy-page-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  padding: 50px;
  text-align: left;
}

.privacy-policy-page-header-wrapper {
  width: 100%;
}

.privacy-policy-page-header {
  margin: auto;
  text-transform: uppercase;
  text-align: center;
  color: #1f0f5a;
}

.privacy-policy-page-wrapper h4 {
  text-transform: uppercase;
  margin: 10px 0;
}

.indent {
  margin-left: 2rem;
}

/* Media Queries */
/* Tablets */
@media screen and (max-width: 1180px) and (max-height: 1368px){
  /* Index page */
  .intro-slide-1, .intro-slide-2 {
    margin-top: 5%;
    padding: unset;
    display: block;
  }

  .intro-image {
    padding-bottom: 1rem;
  }

  /*footer*/
  .footer-container.container {
    margin-top: 5%;
  }

  #footer-middle-widget-area-3 {
    align-self: flex-start;
  }

  .ewf-row, .footer-sections-container {
    font-size: small;
  }

  .rights {
    font-size: small;
  }

  .intro, .intro-2 {
    width: 80%;
    margin: auto;
    padding: 2%;
  }

  .intro-header, .intro-header-2 {
    width: 60%;
    margin-top: 2%;
  }

  .intro-header-2 {
    width: 60%;
    margin-top: 2%;
  }

  .intro-subheader-2 {
    width: 85%;
    margin-top: 2%;
  }

  .intro-text, .intro-text-2 {
    padding: 3% 0;
  }

  .intro-text-2 {
    width: 85%;
  }

  .logo-container, .company-container, .information-container, .socials-container, .socials-container {
    width: 20vw;
    margin: 5% auto 2% auto;
  }

  /* About Page */
  .about-page-container.container {
    margin-top: 5%;
  }

  .about-page-header {
    padding-top: 1rem;
  }

  /* Services Page */
  .services-page-container.container {
    margin-top: 5%;
  }

  .services-page-service {
    margin-bottom: 1rem;
  }

  .services-page-service:last-child, .services-page-service p:last-child {
    margin-bottom: 0;
  }

  .services-page-header {
    font-size: x-large;
  }

  .services-page-subheader {
    font-size: large;
    margin-bottom: 1rem;
  }

  .services-page-service-subheader {
    font-size: medium;
  }

  .services-page-service p {
    font-size: small;
  }

  /* Games Studio Page */
  .games-studio-page-container.container {
    margin-top: 5%;
    margin-bottom: 0;
  }

  .slide-wrapper {
    padding: 1rem 1rem 0;
  }

  .slide-header {
    font-size: xx-large;
  }

  .slide-description {
    font-size: large;
    padding: 1rem;
  }

  .slide-details {
    font-size: medium;
    padding: 1rem;
    margin-bottom: 1rem;
  }

  /* Contact Page */
  .contact-page-container.container {
    margin: 5% auto;
    padding: 1rem;
  }

  .contact-page-form-container {
    align-content: unset;
    justify-content: unset
  }

  .contact-page-header {
    font-size: xx-large;
  }

  .contact-page-body {
    text-align: left;
    font-size: large;
  }

  .contact-form-button.btn-lg {
    font-size: .7rem;
    padding: .5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
}

/* Mobiles Portrait */
@media screen and (max-width: 414px) and (max-height: 915px) {
  /* Index page */
  .splide__track.splide__track--loop.splide__track--ltr.splide__track--draggable {
    margin-top: 7%;
  }

  .intro-slide-1, .intro-slide-2 {
    margin-top: 5%;
    padding: unset;
    display: block;
  }

  .intro-header, .intro-header-2 {
    width: 100%;
  }

  .intro-header h1, .intro-header-2 h1 {
    font-size: x-large;
    padding-top: 1rem;
  }

  .intro-text h4 {
    font-size: large;
  }

  .intro-text-2 h4 {
    font-size: x-large;
  }

  .intro-text p {
    font-size: medium;
  }

  .intro-text-2 p {
    font-size: large;
  }

  .intro-image, .intro-image-2 {
    padding-bottom: 1rem;
  }

  .intro-image-2 {
    padding: 1rem;
    margin-bottom: 1rem;
  }

  .read-more {
    margin-top: 1.5rem;
  }

  .read-more-button.btn.btn-lg {
    padding: 10px;
  }

  /*footer*/
  .footer-container.container {
    margin-top: 5%;
  }

  .footer-sections-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  .logo-container, .company-container, .information-container, .socials-container, .socials-container {
    width: 100%;
    margin: 5% auto 0 auto;
  }

  .logo-container img {
    margin: 5%;
    width: 75px;
  }

  .horizontal-rule {
    margin: 5% auto;
  }

  .rights, .footer-sections-container {
    font-size: small;
  }

  .ewf-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    font-size: small;
  }

  #footer-middle-widget-area-1 {
    padding-bottom: 1rem;
  }

  #footer-middle-widget-area-2, #footer-middle-widget-area-3 {
    width: unset;
    padding: unset;
    margin: auto;
  }

  .images-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
  }

  /* About Page */
  .about-page-container.container {
    margin-top: 5%;
  }

  .about-page-header {
    padding-top: 1rem;
  }

  /* Services Page */
  .services-page-container.container {
    margin-top: 5%;
    padding: 20px;
  }

  .services-page-header {
    font-size: x-large;
  }

  .services-page-subheader {
    font-size: large;
    margin-bottom: 1rem;
  }

  .services-page-service-subheader {
    font-size: medium;
  }

  .services-page-service p {
    font-size: small;
  }

  .services-page-service {
    margin-bottom: 1rem;
  }

  .services-page-service:last-child, .services-page-service p:last-child {
    margin-bottom: 0;
  }

  /* Games Studio Page */
  .games-studio-page-container.container {
    margin-top: 5%;
    margin-bottom: 0;
  }

  .slide-wrapper {
    padding: 1rem 1rem 0;
  }

  .slide-header {
    font-size: large;
  }

  .slide-description {
    font-size: x-small;
    padding: 0;
  }

  .slide-details {
    font-size: x-small;
    padding: 0;
    margin-bottom: 1rem;
  }

  /* Contact Page */
  .contact-page-container.container {
    margin: 5% auto;
  }

  .contact-page-header {
    font-size: large;
  }

  .contact-page-body {
    text-align: left;
    font-size: small;
  }

  .contact-form-button.btn-lg {
    font-size: .5rem;
    padding: .5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  /* Privacy Policy Page */
  .privacy-policy-page-container.container {
    padding: 1rem;
    margin: 5% auto;
  }

  .privacy-policy-page-wrapper {
    padding: 0;
  }

  .privacy-policy-page-header {
    font-size: x-large;
  }

  .privacy-policy-page-wrapper h4 {
    font-size: medium;
  }

  .privacy-policy-page-wrapper p {
    font-size: small;
  }
}

@media screen and (max-width: 320px) and (max-height: 658px){
  .intro-header h1 {
    font-size: xx-large;
  }

  .intro-subheader h2 {
    font-size: x-large;
  }

  .intro-text {
    margin: .855rem;
  }
}

/* Mobiles Landscape */
@media screen and (max-width: 667px) and (max-height: 720px){
  /* Home Page */
  .splide__track.splide__track--loop.splide__track--ltr.splide__track--draggable {
    margin-top: 5%;
  }

  /* Footer */
  .logo-container {
    width: 10vw;
  }

  .logo-container img {
    width: 100%;
  }

  .company-container-header, .information-container-header, .socials-container-header, .socials-container-header {
    font-size: large;
  }

  .ewf-row, .footer-sections-container, .rights {
    font-size: small;
  }

  /* Services Page */
  .services-page-header {
    font-size: x-large;
  }

  .services-page-subheader {
    font-size: large;
    margin-bottom: 1rem;
  }

  .services-page-service-subheader {
    font-size: medium;
  }

  .services-page-service p {
    font-size: small;
  }

  .services-page-service {
    margin-bottom: 1rem;
  }

  .services-page-service:last-child, .services-page-service p:last-child {
    margin-bottom: 0;
  }

  /* Games Studio Page */
  .games-studio-page-container.container {
    margin-top: 5%;
    margin-bottom: 0;
  }

  .slide-wrapper {
    padding: 1rem 1rem 0;
  }

  .slide-header {
    font-size: x-large;
  }

  .slide-description {
    font-size: small;
    padding: 0;
  }

  .slide-details {
    font-size: small;
    padding: 0;
    margin-bottom: 1rem;
  }

  /* Contact Page */
  .contact-page-container.container {
    margin: 5% auto;
  }

  .contact-page-header {
    font-size: x-large;
  }

  .contact-page-body {
    text-align: left;
    font-size: medium;
  }

  .contact-form-button.btn-lg {
    font-size: .8rem;
    padding: .5rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  /* Privacy Policy Page */
  .privacy-policy-page-container.container {
    padding: 1rem;
    margin: 5% auto;
  }

  .privacy-policy-page-wrapper {
    padding: 0;
  }

  .privacy-policy-page-header {
    font-size: x-large;
  }

  .privacy-policy-page-wrapper h4 {
    font-size: medium;
  }

  .privacy-policy-page-wrapper p {
    font-size: small;
  }
}

@media screen and (max-width: 375px) and (max-height: 720px){
  .logo-container img {
    width: 75px;
  }

  .rights {
    font-size: x-small;
  }
}
